import './styles/Footer.css';

function Footer(){
    return (
        <div className="footer">
            <p>Tyler Dansby 2022</p>
        </div>
    )
}

export default Footer;